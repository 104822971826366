import historyProvider from "./historyProvider";
import stream from "./stream";

const supportedResolutions = ["1", "5", "15", "30", "60", "D", "W", "M"];

const config = {
  supported_resolutions: supportedResolutions,
};

const Datafeed = {
  onReady: (cb) => {
    setTimeout(() => cb(config), 0);
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    historyProvider
      .search(userInput, exchange, symbolType)
      .then((result) => {
        onResultReadyCallback(result);
      })
      .catch((err) => {
        console.log({ err });
      });
  },
  resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
    // expects a symbolInfo object in response
    historyProvider
      .getInfo(symbolName)
      .then((info) => {
        onSymbolResolvedCallback(info);
      })
      .catch((err) => {
        console.log({ err });
      });
  },
  getBars: function (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) {
    let { from, to, firstDataRequest } = periodParams;
    if (
      resolution == 1 ||
      resolution == 5 ||
      resolution == 15 ||
      resolution == 30
    ) {
      from = from - 10 * 24 * 60 * 60;
    } else if (resolution === "1D") {
      from = from - 30 * 24 * 60 * 60;
    }

    historyProvider
      .getBars(symbolInfo, resolution, from, to, firstDataRequest)
      .then((bars) => {
        if (bars.length) {
          onHistoryCallback(bars, { noData: false });
        } else {
          onHistoryCallback(bars, { noData: true });
        }
      })
      .catch((err) => {
        console.log({ err });
        onErrorCallback(err);
      });
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback
  ) => {
    console.log(
      "[subscribeBars]: Method call with subscriberUID:",
      subscribeUID
    );

    stream.subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback
    );
  },
  unsubscribeBars: (subscriberUID) => {
    console.log(
      "[unsubscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );

    stream.unsubscribeFromStream(subscriberUID);
  },
  calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
    return resolution < 60
      ? { resolutionBack: "M", intervalBack: "1" }
      : undefined;
  },
  getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {},
  getTimeScaleMarks: (
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) => {},
  getServerTime: (cb) => {},
};

export default Datafeed;
