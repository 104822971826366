import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore";
import { storeChartActions } from "../../../store/slices/chartSlice";

export default function useNotePress() {
  const { displayed, isShow } = useAppSelector(
    (state) => state.chart.notePressMobile
  );
  const [isMobile, setIsMobile] = useState(false);

  const dispatch = useAppDispatch();

  const handleHideNotePressMobile = () => {
    dispatch(storeChartActions.updateDisplayedNotePressMobile(true));
    dispatch(storeChartActions.toggleIsShowNotePressMobile(false));
  };

  const handleShowNotePressMobile = () => {
    dispatch(storeChartActions.toggleIsShowNotePressMobile(true));
  };

  useEffect(() => {
    try {
      const isNowMobile = window.innerWidth < 500;
      setIsMobile(isNowMobile);
      if (!isNowMobile) {
        return;
      }
      // nếu là mobile và chưa từng hiển thị
      if (!displayed) {
        handleShowNotePressMobile();
      }
    } catch (error) {}
  }, [displayed]);

  return {
    handleHideNotePressMobile,
    handleShowNotePressMobile,
    isShowNote: isShow && isMobile,
  };
}
