// src/store/store.ts
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // bạn có thể thay storage bằng storage/session nếu muốn sử dụng session storage
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import chartSlice, { IChartState } from "./slices/chartSlice";

const customReconciler = (
  inboundState: any,
  originalState: any,
  reducedState: any
) => {
  if (
    !inboundState ||
    inboundState._persist.version !== originalState._persist.version ||
    Object.keys(inboundState).length - 1 !== Object.keys(reducedState).length
  ) {
    // Reset về initialState nếu có sự khác biệt về version hoặc cấu trúc
    return reducedState;
  }

  // Hợp nhất state cũ và mới nếu không có vấn đề
  return { ...reducedState, ...inboundState };
};
// Cấu hình persist
const persistConfig: PersistConfig<{
  chart: IChartState;
}> = {
  key: "root", // key để lưu trữ trong storage
  storage, // có thể thay bằng sessionStorage nếu muốn dùng session
  version: 1,
  stateReconciler: customReconciler,
};

// Kết hợp reducers
const rootReducer = combineReducers({
  chart: chartSlice,
});

// Áp dụng persist vào rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Tạo store với persisted reducer và middleware
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Tạo persistor
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
