const env = process.env.REACT_APP_ENV || "production";
export const DCHART_VERSION = "1.1.16";

const Configs = {
  uat: {
    DCHART_API: "https://dchart-api.vndirect.com.vn/dchart",
    DCHART_SOCKET: "https://dchart-socket.vndirect.com.vn",
    CHART_STORAGE_API: "https://chart-api.vndirect.com.vn",
  },
  production: {
    DCHART_API: "https://dchart-api.vndirect.com.vn/dchart",
    DCHART_SOCKET: "https://dchart-socket.vndirect.com.vn",
    CHART_STORAGE_API: "https://chart-api.vndirect.com.vn",
  },
  staging: {
    DCHART_API: "https://dchart-api-staging.vndirect.com.vn/dchart",
    DCHART_SOCKET: "https://dchart-socket.vndirect.com.vn",
    CHART_STORAGE_API: "https://chart-api-staging.vndirect.com.vn",
  },
};

export const DCHART_API = Configs[env].DCHART_API;
export const DCHART_SOCKET = Configs[env].DCHART_SOCKET;
export const CHART_STORAGE_API = Configs[env].CHART_STORAGE_API;
