import { useLayoutEffect, useState } from "react";
import {
  EntityId,
  IChartingLibraryWidget,
  StudyInputValue,
} from "../../../charting_library/charting_library";
import { storeChartActions } from "../../../store/slices/chartSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore";

import { useLocation } from "react-router-dom";
import {
  indicators,
  TKeyStudy,
  movingAverageStudies,
  TShortId,
  emaStudies,
  macdStudies,
  rsiStudies,
  bollStudies,
  compareStudies,
} from "../constants";

export interface ILayout {
  charts: [
    {
      panes?: [
        {
          sources?: [
            {
              state?: {
                inputs?: {
                  length?: number;
                  fastLength?: number;
                  slowLength?: number;
                  mult?: number;
                  source?: string;
                  symbol?: string;
                  in_0: number | string;
                  in_1: number | string;
                  in_2: number | string;
                  in_3: number | string;
                };
              };
              metaInfo?: { shortId?: string };
              id: EntityId;
            }
          ];
        }
      ];
      chartProperties?: object;
    }
  ];
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function useChart() {
  const dispatch = useAppDispatch();
  const layouts = useAppSelector((state) => state.chart.layouts);
  const [cloneTvWidget, setCloneTvWidget] = useState<IChartingLibraryWidget>();
  const query = useQuery();

  const saveRefWithSymbol = (widget?: IChartingLibraryWidget) => {
    try {
      const crWidget = widget || cloneTvWidget;
      const symbol = crWidget?.symbolInterval().symbol;
      if (!symbol) return;
      crWidget?.save((layout) => {
        dispatch(
          storeChartActions.updateLayout({
            symbol: symbol,
            config: layout,
          })
        );
      });
    } catch (error) {}
  };

  const loadRefWithSymbol = (crTvWidget: IChartingLibraryWidget) => {
    const symbol = crTvWidget.symbolInterval().symbol;
    if (!layouts?.[symbol]) return undefined;
    const cloneLayout = { ...layouts?.[symbol] } as ILayout;
    return cloneLayout;
  };
  /**
   * @handleRemoveStudy kiểm tra các study được đưa vào danh sách ignore -> xóa bỏ bằng hàm removeEntity
   * @showSymbolLabels kiểm tra có compare thì hiển thị label symbol
   */

  const handleRemoveStudy = (tvWidget: IChartingLibraryWidget) => {
    const ignoreIndicator = query
      .get("ignoreIndicator")
      ?.split(",") as typeof indicators;

    /**
     * @ignoreCompare danh sách mã sẽ xoá bỏ khỏi chart
     * VD: ...&ingoreCompare=VN30 params này sẽ được truyền vào khi sử dụng tại màn trading pad để ẩn mã VN30
     * ignore > compare -> Khi cả ignore và compare đều có mã đó thì ignore sẽ bị ẩn đi
     */
    const ignoreCompare = query.get("ignoreCompare")?.split(",");

    setTimeout(() => {
      tvWidget
        .chart()
        .getAllStudies()
        .forEach((item) => {
          // chuyển sang object
          /** Vui lòng xem kỹ type của inputValues,
           * getInputValues trả ra mảng và hàm reduce convert thành object để tiện so sánh
           */
          const inputValues: { [key in TKeyStudy]?: StudyInputValue } = tvWidget
            .chart()
            .getStudyById(item.id)
            .getInputValues()
            .reduce((acc: { [key in TKeyStudy]?: StudyInputValue }, item) => {
              acc[item.id as TKeyStudy] = item.value;
              return acc;
            }, {} as { [key in TKeyStudy]?: StudyInputValue });

          if (ignoreIndicator?.includes("ma")) {
            const isHasIndicator = !!movingAverageStudies.find(
              (item) => inputValues?.length === item.length
            );
            if (
              (item.name as TShortId) === "Moving Average" &&
              isHasIndicator
            ) {
              tvWidget.chart().removeEntity(item.id);
            }
          }

          if (ignoreIndicator?.includes("ema")) {
            const isHasIndicator = !!emaStudies.find(
              (item) => item.length === inputValues.length
            );
            if (
              (item.name as TShortId) === "Moving Average Exponential" &&
              isHasIndicator
            ) {
              tvWidget.chart().removeEntity(item.id);
            }
          }

          if (ignoreIndicator?.includes("macd")) {
            const isHasIndicator = !!macdStudies.find(
              (item) =>
                item.fastLength === inputValues?.in_0 &&
                item.slowLength === inputValues.in_1 &&
                item.source === inputValues.in_3
            );
            if ((item.name as TShortId) === "MACD" && isHasIndicator) {
              tvWidget.chart().removeEntity(item.id);
            }
          }

          if (ignoreIndicator?.includes("rsi")) {
            const isHasIndicator = !!rsiStudies.find(
              (item) => item.length === inputValues?.length
            );
            if (
              (item.name as TShortId) === "Relative Strength Index" &&
              isHasIndicator
            ) {
              tvWidget.chart().removeEntity(item.id);
            }
          }

          if (ignoreIndicator?.includes("boll")) {
            const isHasIndicator = !!bollStudies.find(
              (item) =>
                item.length === inputValues?.in_0 &&
                item.mult === inputValues.in_1
            );
            if (
              (item.name as TShortId) === "Bollinger Bands" &&
              isHasIndicator
            ) {
              tvWidget.chart().removeEntity(item.id);
            }
          }

          if (ignoreCompare) {
            const isHasIndicator = !!compareStudies.find(
              (item) => item.symbol === inputValues.symbol
            );
            if ((item.name as TShortId) === "Compare" && isHasIndicator) {
              tvWidget.chart().removeEntity(item.id);
            }
          }
        });

      setTimeout(() => {
        tvWidget
          .chart()
          .getAllStudies()
          .forEach((item) => {
            if (item.name === "Compare") {
              tvWidget.applyOverrides({
                "scalesProperties.showSymbolLabels": true,
              });
            }
          });
      }, 200);
    }, 200);
  };

  useLayoutEffect(() => {
    const firstSave = setTimeout(() => {
      saveRefWithSymbol(cloneTvWidget);
    }, 2000);
    return () => {
      clearTimeout(firstSave);
    };
  }, [cloneTvWidget]);

  return {
    saveRefWithSymbol,
    loadRefWithSymbol,
    setCloneTvWidget,
    handleRemoveStudy,
  };
}
