import { jwtDecode } from "jwt-decode";

const tokenName = "accessToken";
const getCookie = (name) => {
  var i,
    x,
    y,
    ARRcookies = document.cookie.split(";");
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x == name) {
      return unescape(y);
    }
  }
};

export const getRawToken = () => {
  return getCookie(tokenName);
};

export const getParsedToken = (token) => {
  if (!token) {
    token = getRawToken();
  }

  if (token && token.length > 0 && token !== '""') {
    return new jwtDecode(token);
  }

  return null;
};
