import { createAction, createSlice } from "@reduxjs/toolkit";

export type IConfig = object;
interface ISaveLayout {
  [symbol: string]: IConfig;
}
export interface IChartState {
  layouts: ISaveLayout;
  notePressMobile: {
    isShow: boolean;
    displayed: boolean;
  };
}

const initialState: IChartState = {
  layouts: {},
  notePressMobile: {
    displayed: false,
    isShow: false,
  },
};

const updateLayout = createAction<{ symbol: string; config: IConfig }>(
  "chart/saveLayout"
);

const toggleIsShowNotePressMobile = createAction<boolean>(
  "chart/toggleIsShowNotePressMobile"
);

const updateDisplayedNotePressMobile = createAction<boolean>(
  "chart/updateDisplayedNotePressMobile"
);

export const chartSlice = createSlice({
  initialState,
  name: "chart",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateLayout, (state, { payload }) => {
      state.layouts[payload.symbol] = payload.config;
    });
    builder.addCase(toggleIsShowNotePressMobile, (state, { payload }) => {
      state.notePressMobile.isShow = payload;
    });
    builder.addCase(updateDisplayedNotePressMobile, (state, { payload }) => {
      state.notePressMobile.displayed = payload;
    });
  },
});

export const storeChartActions = {
  updateLayout,
  toggleIsShowNotePressMobile,
  updateDisplayedNotePressMobile,
};

export default chartSlice.reducer;
