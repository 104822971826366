export type TShortId =
  | "Volume"
  | "Moving Average"
  | "Moving Average Exponential"
  | "Moving Average Convergence/Divergence"
  | "Relative Strength Index"
  | "Bollinger Bands"
  | "MACD"
  | "Compare";

export type TKeyStudy =
  | keyof (typeof movingAverageStudies)[0]
  | keyof (typeof emaStudies)[0]
  | keyof (typeof macdStudies)[0]
  | keyof (typeof rsiStudies)[0]
  | keyof (typeof bollStudies)[0]
  | keyof (typeof compareStudies)[0]
  | "in_0"
  | "in_1"
  | "in_2"
  | "in_3";
export const movingAverageStudies = [
  { length: 20, color: "#F7941D" },
  { length: 50, color: "#1DCF6F" },
];

export const emaStudies = [
  { length: 10, color: "#49BEE3" },
  { length: 50, color: "#1DCF6F" },
  { length: 200, color: "#D452E9" },
];
export type TIndicatior = "ma" | "ema" | "macd" | "rsi" | "boll";
export const indicators: TIndicatior[] = ["ma", "ema", "macd", "rsi", "boll"];

interface IMacdStudy {
  /** @fastLength id study: in_0 */
  fastLength: number;
  /** @slowLength id study: in_1 */
  slowLength: number;
  /** @source id study: in_3 */
  source: string;
}

export const macdStudies: IMacdStudy[] = [
  {
    fastLength: 12,
    slowLength: 26,
    source: "close",
  },
];

export const rsiStudies = [{ length: 14 }];
export const bollStudies = [{ length: 20, mult: 2 }];
export const compareStudies = [{ source: "open", symbol: "VN30" }];

export const KEY_SYNC_ACTIVE_INDICATORS = 'SYNC_ACTIVE_INDICATORS_';
export const KEY_CHANGE_SYMBOL = 'KEY_CHANGE_SYMBOL';