// api/stream.js
import historyProvider from "./historyProvider.js";
// we use Socket.io client to connect to cryptocompare's socket.io stream
import { DCHART_SOCKET } from "../../../config.js";
const io = require("socket.io-client");
// import { io } from "socket.io-client";
// const io = require("socket.io-client");
const socket_url = DCHART_SOCKET + "/socket.io";
const socket = io(socket_url, {
  query: {
    symbol: "VN30F1M",
  },
});

socket.on("connect", () => {
  console.log("===Socket connected");
});

socket.on("disconnect", (e) => {
  console.log("===Socket disconnected:", e);
});

socket.on("error", (err) => {
  console.log("====socket error", err);
});

socket.on("price", (msg) => {
  const sub = channelToSubscription[msg.symbol];
  // here we get all events the CryptoCompare connection has subscribed to
  // we need to send this new data to our subscribed charts
  if (sub) {
    const data = {
      symbol: msg.symbol,
      ts: Math.floor(msg.time / 1000),
      volume: parseFloat(msg.volume),
      price: parseFloat(msg.price),
    };
    // console.log(
    //   `SOCKET-----Time is %c${new Date(msg.time)}: %cPRICE=${msg.price} `,
    //   "color: red",
    //   "color: green"
    // );

    // disregard the initial catchup snapshot of trades for already closed candles
    if (data.ts < sub.lastBar.time / 1000) {
      return;
    }

    let _lastBar = updateBar(data, sub);

    // send the most recent bar back to TV's realtimeUpdate callback
    sub.listener(_lastBar);
    // console.log("====drawl last bar", _lastBar);
    // update our own record of lastBar
    sub.lastBar = _lastBar;
  }
});

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
  let lastBar = sub.lastBar;
  let resolution = sub.resolution;
  if (resolution.includes("D")) {
    // 1 day in minutes === 1440
    resolution = 1440;
  } else if (resolution.includes("W")) {
    // 1 week in minutes === 10080
    resolution = 10080;
  }
  let coeff = resolution * 60;
  // console.log({coeff})
  let rounded = Math.floor(data.ts / coeff) * coeff;
  let lastBarSec = lastBar.time / 1000;
  let _lastBar;
  let isLastBarNull = JSON.stringify(lastBar) === "{}";

  if (isLastBarNull || rounded > lastBarSec) {
    // create a new candle, use last close as open **PERSONAL CHOICE**
    _lastBar = {
      time: rounded * 1000,
      //open: lastBar.close,
      open: data.price,
      high: isLastBarNull ? data.price : lastBar.close,
      low: isLastBarNull ? data.price : lastBar.close,
      close: data.price,
      volume: data.volume,
    };
  } else {
    // update lastBar candle!
    if (data.price < lastBar.low) {
      lastBar.low = data.price;
    } else if (data.price > lastBar.high) {
      lastBar.high = data.price;
    }

    lastBar.volume += data.volume;
    lastBar.close = data.price;
    _lastBar = lastBar;
  }
  return _lastBar;
}

// keep track of subscriptions
let _subs = [];
const channelToSubscription = {};
const stream = {
  subscribeOnStream: function (
    symbolInfo,
    resolution,
    updateCb,
    uid,
    resetCache
  ) {
    const channelString = symbolInfo.name;

    let newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name]
        ? historyProvider.history[symbolInfo.name].lastBar
        : {},
      listener: updateCb,
    };

    if (
      Object.keys(channelToSubscription).includes(channelString) &&
      channelToSubscription[channelString]
    ) {
      channelToSubscription[channelString] = newSub;
    } else {
      socket.emit("addsymbol", channelString);
      channelToSubscription[channelString] = newSub;
    }

    // socket.emit("addsymbol", channelString);

    // activeSub.push(uid);
    // activeSub = [...new Set(activeSub)];
    // _subs.push(newSub);
  },

  unsubscribeFromStream: function (uid) {
    const channelString = uid.split("_")[0];

    if (
      channelToSubscription[channelString] &&
      channelToSubscription[channelString].uid === uid
    ) {
      channelToSubscription[channelString] = null;
      socket.emit("removesymbol", channelString);
    }

    // let subIndex = _subs.findIndex((e) => e.uid === uid);
    // if (subIndex === -1) {
    //   return;
    // }
    // let sub = _subs[subIndex];

    // const countSymbolSub = activeSub.filter(
    //   (item) => item.split("_")[0] === sub.channelString
    // ).length; // problem
    // if (countSymbolSub === 1) {
    //   socket.emit("removesymbol", sub.channelString);
    //   // activeSub = activeSub.filter(
    //   //   (item) => item.split("_")[0] !== sub.channelString
    //   // );
    // }
    // activeSub = activeSub.filter((item) => item !== uid);
    // _subs.splice(subIndex, 1);
  },
};

export default stream;
