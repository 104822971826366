import axios from "axios";
import { DCHART_API } from "../../../config";
const api_root = DCHART_API;
const history = {};

export default {
  history: history,

  getBars: function (symbolInfo, resolution, from, to, first) {
    to = Math.min(to, Math.floor(new Date().getTime() / 1000));
    const url = `/history?resolution=${resolution}&symbol=${symbolInfo.name}&from=${from}&to=${to}`;
    return axios({
      method: "get",
      url: `${api_root}${url}`,
      headers: {},
    }).then((response) => {
      let data = response.data;
      let bars = [];
      if (data.t) {
        let length = data.t.length;
        if (length > 0) {
          for (let index = 0; index < length; index++) {
            bars.push({
              time: data.t[index] * 1000, //TradingView requires bar time in ms
              low: data.l[index],
              high: data.h[index],
              open: data.o[index],
              close: data.c[index],
              volume: data.v[index],
              isLastBar: false,
              isBarClosed: true,
            });
            // console.log(
            //   `SNAPSHOT-----Time is %c${new Date(data.t[index] * 1000)}: %cO=${
            //     data.o[index]
            //   } %cC=${data.c[index]}`,
            //   "color: red",
            //   "color: green",
            //   "color: blue"
            // );
          }
          var lastBar = bars[bars.length - 1];
          lastBar.isLastBar = true;
          lastBar.isBarClosed = false;
          if (first) {
            history[symbolInfo.name] = { lastBar: lastBar };
          }
        }
      }
      return bars;
    });
  },

  getInfo: function (symbolInfo) {
    const url = `/symbols?symbol=${symbolInfo}`;
    return axios({
      method: "get",
      url: `${api_root}${url}`,
      headers: {},
    }).then((response) => {
      let info = response.data;
      return info;
    });
  },

  search: function (userInput, type, exchange) {
    const url = `/search?limit=30&query=${userInput}&type=${type}&exchange=${exchange}`;
    return axios({
      method: "get",
      url: `${api_root}${url}`,
      headers: {},
    }).then((response) => {
      let result = response.data;
      return result;
    });
  },
};
