import React, { useEffect, useRef, useState } from "react";
import {
  AvailableSaveloadVersions,
  ChartingLibraryFeatureset,
  IChartingLibraryWidget,
  LanguageCode,
  ResolutionString,
  StudyInputValue,
  ThemeName,
  TradingTerminalFeatureset,
  widget,
} from "../../charting_library";
import { getParsedToken } from "../../utils/TokenUtil";
import Datafeed from "./api";
import { CHART_STORAGE_API } from "../../config";
import useChart, { ILayout, useQuery } from "./hooks/useChart";
import useNotePress from "./hooks/useNotePress";
import clsx from "clsx";
import {
  indicators,
  movingAverageStudies,
  emaStudies,
  macdStudies,
  rsiStudies,
  bollStudies,
  compareStudies,
  TShortId,
  TKeyStudy,
  TIndicatior,
  KEY_SYNC_ACTIVE_INDICATORS,
  KEY_CHANGE_SYMBOL,
} from "./constants";

const STORAGE_SYMBOL_KEY = "vnds.tradingView.widget.symbol";

interface IProps {
  containerId?: string;
  libraryPath?: string;
  chartsStorageUrl?: string;
  chartsStorageApiVersion?: AvailableSaveloadVersions;
  clientId?: string;
  userId?: string;
  fullscreen?: boolean;
  disabledFeatures?: ChartingLibraryFeatureset[] | TradingTerminalFeatureset[];
  studiesOverrides?: {};
}

const TVChartContainer = ({
  containerId = "tv_chart_container",
  libraryPath = "/charting_library/",
  chartsStorageUrl = CHART_STORAGE_API,
  chartsStorageApiVersion = "1.1",
  clientId = "vnds_trading_view",
  fullscreen = false,
  disabledFeatures = ["volume_force_overlay"],
  studiesOverrides = {},
}: IProps) => {
  const tvWidgetRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const [isLoading, setIsLoading] = useState(false);

  const query = useQuery();

  const { handleHideNotePressMobile, isShowNote } = useNotePress();

  const {
    loadRefWithSymbol,
    setCloneTvWidget,
    saveRefWithSymbol,
    handleRemoveStudy,
  } = useChart();
  const addTheme = (tvWidget: IChartingLibraryWidget, theme: string) => {
    if (theme == "Dark") {
      tvWidget.applyOverrides({
        // bg
        "paneProperties.background": "#131722",
        "paneProperties.backgroundType": "solid",
        // grid lines
        "paneProperties.horzGridProperties.color": "#363c4e",
        "paneProperties.vertGridProperties.color": "#363c4e",
        //
        "paneProperties.separatorColor": "#787878",
        "scalesProperties.lineColor": "#787878",
        "paneProperties.topMargin": 5,
        "paneProperties.bottomMargin": 5,
      });
      tvWidget.chart().getSeries().setChartStyleProperties(1, {
        upColor: "#53B987",
        downColor: "#EB4D5C",
        borderUpColor: "#53B987",
        borderDownColor: "#EB4D5C",
        wickDownColor: "#7F323F",
        wickUpColor: "#336854",
      });
    } else {
      tvWidget.applyOverrides({
        "paneProperties.background": "#FFFFFF",
        "paneProperties.backgroundType": "solid",
        "paneProperties.horzGridProperties.color": "#E1ECF2",
        "paneProperties.vertGridProperties.color": "#E1ECF2",
        "paneProperties.separatorColor": "#555555",
        "scalesProperties.lineColor": "#555555",
        "paneProperties.topMargin": 5,
        "paneProperties.bottomMargin": 5,
      });
      tvWidget.chart().getSeries().setChartStyleProperties(1, {
        upColor: "#6BA583",
        downColor: "#D75442",
        borderUpColor: "#6BA583",
        borderDownColor: "#D75442",
        wickDownColor: "#7F323F",
        wickUpColor: "#336854",
      });
    }
  };
  const addConfigParams = (
    tvWidget: IChartingLibraryWidget,
    oldRef?: ILayout,
    isDark = false
  ) => {
    try {
      const indicatorsList = (query.get("indicator") || "").split(
        ","
      ) as typeof indicators;
      /**
       * @compare ds mã sẽ được thêm vào chart
       * VD: ...&compare=VN30 params này sẽ được truyền vào khi sử dụng tại màn trading pad để thêm mã VN30
       */
      const compare = query.get("compare");
      /**
       * @ignoreCompare ds mã sẽ xoá bỏ khỏi chart
       * VD: ...&ingoreCompare=VN30 params này sẽ được truyền vào khi sử dụng tại màn trading pad để ẩn mã VN30
       * ignore > compare -> Khi cả ignore và compare đều có mã đó thì ignore sẽ bị ẩn đi
       */
      const ignoreCompare = query.get("ignoreCompare")?.split(",");
      let theme = query.get("theme");
      theme = theme && theme.toLowerCase() === "dark" ? "Dark" : "Light";

      if (oldRef) {
        const cloneOldRef = { ...oldRef };
        delete cloneOldRef.charts?.[0].chartProperties; // bỏ các config màu sắc chart cũ tránh khác theme
        const panes = cloneOldRef.charts?.[0].panes || [];
        /** indicator = ma bị thiếu */
        const missMA: typeof movingAverageStudies = [];
        const missEMA: typeof emaStudies = [];
        const missMACD: typeof macdStudies = [];
        const missRSI: typeof rsiStudies = [];
        const missBoll: typeof bollStudies = [];
        const missCompare: typeof compareStudies = [];

        if (indicatorsList.includes("ma")) {
          movingAverageStudies.forEach((study) => {
            // Kiểm tra nếu `savedTvWidget` đã có study với `shortId = "Moving Average"` và `length` tương ứng
            const existingStudy = panes.some((pane) => {
              const sources = [...(pane?.sources || [])];
              return sources.some((source) => {
                const inputs = source?.state?.inputs;
                const shortId = source?.metaInfo?.shortId as TShortId;
                return (
                  shortId === "Moving Average" &&
                  inputs?.length === study.length
                );
              });
            });

            // Nếu chưa có, tiến hành tạo study
            if (!existingStudy) missMA.push(study);
          });
        }

        if (indicatorsList.includes("ema")) {
          emaStudies.forEach((study) => {
            // Kiểm tra nếu `savedTvWidget` đã có study với `shortId = "Moving Average"` và `length` tương ứng
            const existingStudy = panes.some((pane) => {
              const sources = [...(pane?.sources || [])];
              return sources.some((source) => {
                const inputs = source?.state?.inputs;
                const shortId = source?.metaInfo?.shortId as TShortId;
                return (
                  shortId === "Moving Average Exponential" &&
                  inputs?.length === study.length
                );
              });
            });

            // Nếu chưa có, tiến hành tạo study
            if (!existingStudy) missEMA.push(study);
          });
        }

        if (indicatorsList.includes("macd")) {
          macdStudies.forEach((study) => {
            // Kiểm tra nếu `savedTvWidget` đã có study với `shortId = "Moving Average"` và `length` tương ứng
            const existingStudy = panes.some((pane) => {
              const sources = [...(pane?.sources || [])];
              return sources.some((source) => {
                const inputs = source?.state?.inputs;
                const shortId = source?.metaInfo?.shortId as TShortId;
                return (
                  shortId === "Moving Average Convergence/Divergence" &&
                  inputs?.in_0 === study.fastLength &&
                  inputs?.in_1 === study.slowLength &&
                  inputs?.in_3 === study.source
                );
              });
            });

            // Nếu chưa có, tiến hành tạo study
            if (!existingStudy) missMACD.push(study);
          });
        }
        if (indicatorsList.includes("rsi")) {
          rsiStudies.forEach((study) => {
            // Kiểm tra nếu `savedTvWidget` đã có study với `shortId = "Moving Average"` và `length` tương ứng
            const existingStudy = panes.some((pane) => {
              const sources = [...(pane?.sources || [])];
              return sources.some((source) => {
                const inputs = source?.state?.inputs;
                const shortId = source?.metaInfo?.shortId as TShortId;
                return (
                  shortId === "Relative Strength Index" &&
                  inputs?.length === study.length
                );
              });
            });

            // Nếu chưa có, tiến hành tạo study
            if (!existingStudy) missRSI.push(study);
          });
        }
        if (indicatorsList.includes("boll")) {
          bollStudies.forEach((study) => {
            // Kiểm tra nếu `savedTvWidget` đã có study với `shortId = "Moving Average"` và `length` tương ứng
            const existingStudy = panes.some((pane) => {
              const sources = [...(pane?.sources || [])];
              return sources.some((source) => {
                const inputs = source?.state?.inputs;
                const shortId = source?.metaInfo?.shortId as TShortId;
                return (
                  shortId === "Bollinger Bands" &&
                  inputs?.length === study.length
                );
              });
            });

            // Nếu chưa có, tiến hành tạo study
            if (!existingStudy) missBoll.push(study);
          });
        }

        compareStudies.forEach((study) => {
          const existingStudy = panes.some((pane) => {
            const sources = [...(pane?.sources || [])];
            return sources.some((source) => {
              const inputs = source?.state?.inputs;
              const shortId = source?.metaInfo?.shortId as TShortId;

              if (
                (inputs?.symbol && ignoreCompare?.includes(inputs?.symbol)) ||
                ignoreCompare?.includes(study.symbol)
              ) {
                return true;
              }
              return (
                shortId === "Compare" &&
                inputs?.source === study.source &&
                inputs?.symbol === study.symbol
              );
            });
          });
          // Nếu chưa có, tiến hành tạo study
          if (!existingStudy && compare) missCompare.push(study);
        });

        tvWidget.load(cloneOldRef);
        // config lại theme
        addTheme(tvWidget, theme as string);

        missMA.forEach((maItem) =>
          tvWidget.chart().createStudy(
            "Moving Average",
            false,
            false,
            { length: maItem.length },
            {
              "plot.linewidth": 2,
              "plot.color": maItem.color,
            }
          )
        );
        missEMA.forEach((emaItem) =>
          tvWidget.chart().createStudy(
            "Moving Average Exponential",
            false,
            false,
            { length: emaItem.length },
            {
              "plot.linewidth": 2,
              "plot.color": emaItem.color,
            }
          )
        );
        missMACD.forEach((macdItem) =>
          tvWidget.chart().createStudy(
            "MACD",
            false,
            false,
            {
              fastLength: macdItem.fastLength,
              slowLength: macdItem.slowLength,
              source: macdItem.source,
            },
            {
              "histogram.linewidth": 2,
              "macd.linewidth": 2,
              "signal.linewidth": 2,
              "macd.color": "rgba(0,0,255,0.7)",
              "signal.color": "rgba(255, 87, 51,0.7)",
            }
          )
        );
        missRSI.forEach((rsiItem) =>
          tvWidget.chart().createStudy(
            "Relative Strength Index",
            false,
            false,
            { length: rsiItem.length },
            {
              "plot.linewidth": 2,
            }
          )
        );
        missBoll.forEach((rsiItem) =>
          tvWidget.chart().createStudy(
            "Bollinger Bands",
            false,
            false,
            { length: rsiItem.length, mult: rsiItem.mult },
            {
              "median.linewidth": 2,
              "upper.linewidth": 2,
              "lower.linewidth": 2,
              "upper.color": "rgba(0,0,255,0.7)",
              "lower.color": "rgba(0,0,255,0.7)",
              "median.color": "rgba(255, 87, 51,0.7)",
            }
          )
        );
        missCompare.forEach((compareItem) =>
          tvWidget.chart().createStudy("Compare", false, false, compareItem)
        );

        handleRemoveStudy(tvWidget);
        return;
      }

      tvWidget.chart().createStudy("Volume", false, false, undefined, {
        "volume ma.linewidth": 3,
        "volume ma.display": 15,
        "volume ma.color": "rgba(4, 150, 255, 0.5)",
        "volume.color.0": isDark ? "#EB4D5C" : "rgba(215, 84, 66, 0.75)",
        "volume.color.1": isDark
          ? "rgba(83, 185, 135, 0.4)"
          : "rgba(107, 165, 131, 0.75)",
        "volume.transparency": 60,
      });

      if (indicatorsList.includes("ma")) {
        movingAverageStudies.forEach((maItem) =>
          tvWidget.chart().createStudy(
            "Moving Average",
            false,
            false,
            { length: maItem.length },
            {
              "plot.linewidth": 2,
              "plot.color": maItem.color,
            }
          )
        );
      }
      if (indicatorsList.includes("ema")) {
        emaStudies.forEach((study) => {
          tvWidget.chart().createStudy(
            "Moving Average Exponential",
            false,
            false,
            { length: study.length },
            {
              "plot.linewidth": 2,
              "plot.color": study.color,
            }
          );
        });
      }
      if (indicatorsList.includes("macd")) {
        tvWidget.chart().createStudy(
          "MACD",
          false,
          false,
          { fastLength: 12, slowLength: 26, source: "close" },
          {
            "histogram.linewidth": 2,
            "macd.linewidth": 2,
            "signal.linewidth": 2,
            "macd.color": "rgba(0,0,255,0.7)",
            "signal.color": "rgba(255, 87, 51,0.7)",
          }
        );
      }
      if (indicatorsList.includes("rsi")) {
        tvWidget.chart().createStudy(
          "Relative Strength Index",
          false,
          false,
          { length: 14 },
          {
            "plot.linewidth": 2,
          }
        );
      }
      if (indicatorsList.includes("boll")) {
        tvWidget.chart().createStudy(
          "Bollinger Bands",
          false,
          false,
          { length: 20, mult: 2 },
          {
            "median.linewidth": 2,
            "upper.linewidth": 2,
            "lower.linewidth": 2,
            "upper.color": "rgba(0,0,255,0.7)",
            "lower.color": "rgba(0,0,255,0.7)",
            "median.color": "rgba(255, 87, 51,0.7)",
          }
        );
      }

      if (compare) {
        tvWidget.chart().createStudy("Compare", false, false, {
          source: "open",
          symbol: "VN30",
        });
      }
    } catch (error) {}
  };

  const hideTools = (tvWidget: IChartingLibraryWidget) => {
    const isHidetools = query.get("hidetools") === "true";
    const isCrShow = tvWidget
      .chart()
      .getCheckableActionState("drawingToolbarAction");

    if (isHidetools && isCrShow)
      tvWidget.chart().executeActionById("drawingToolbarAction");
  };

  useEffect(() => {
    const mainUrl =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;

    const isMobile = window.innerWidth <= 768;

    let isAvailable =
      mainUrl.indexOf("vndirect.com.vn") > -1 ||
      mainUrl.length === 0 ||
      mainUrl === "" ||
      mainUrl.indexOf("vntrade.fnsyrus.com") > -1 ||
      mainUrl.indexOf("vninvest.bualuang.co.th") > -1 ||
      mainUrl.indexOf("viet-kabu.com") > -1 ||
      mainUrl.indexOf("minhnh87") > -1;

    if (!isAvailable) {
      return;
    }

    const isAutosave = query.get("autosave") === "true";
    let isLoadLastChart = query.get("loadlastchart") === "true";

    let hideHeader = query.get("hideheader") === "true";
    let symbol = query.get("symbol");
    if (symbol) {
      localStorage.setItem(STORAGE_SYMBOL_KEY, symbol);
    } else {
      let savedSymbol = localStorage.getItem(STORAGE_SYMBOL_KEY);
      if (savedSymbol) {
        symbol = savedSymbol;
      } else {
        symbol = "VN30F1M";
      }
    }

    let timeframe = (
      query.get("timeframe")
        ? query.get("timeframe")
        : ("D" as ResolutionString)
    ) as ResolutionString;

    let theme = query.get("theme");
    theme = theme && theme.toLowerCase() === "dark" ? "Dark" : "Light";
    const domain = query.get("domain");

    let tokenObj = getParsedToken(query.get("token"));
    const languageMap = {
      jp: "ja",
      vi: "vi",
      en: "en",
      zh: "zh",
    };
    let languageQuery = query.get("language") as keyof typeof languageMap;

    let locale = languageMap[languageQuery] ? languageMap[languageQuery] : "en";

    let overrides = { backgroundType: "solid" };

    let user_id = "public_user_id";
    if (tokenObj) {
      user_id = `vnds-${tokenObj.customerId}`;
    } else if (query.get("user")) {
      user_id = query.get("user") || "";
    }

    const disabled_features:
      | ChartingLibraryFeatureset[]
      | TradingTerminalFeatureset[] = [...disabledFeatures];

    // if (hideTools) {
    //   disabled_features.push("left_toolbar");
    // }
    if (hideHeader) {
      disabled_features.push("header_widget");
    }

    const tvWidget = new widget({
      symbol: symbol,
      datafeed: Datafeed,
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
      //   "https://demo_feed.tradingview.com"
      // ),
      debug: false,
      width: "100%" as unknown as number,
      height: "100%" as unknown as number,
      interval: timeframe,
      time_frames: [
        { text: "5y", resolution: "W" as ResolutionString },
        { text: "1y", resolution: "W" as ResolutionString },
        { text: "3m", resolution: "D" as ResolutionString },
        { text: "1m", resolution: "D" as ResolutionString },
        { text: "5d", resolution: "5" as ResolutionString },
        { text: "1d", resolution: "1" as ResolutionString },
      ],
      container: tvWidgetRef.current,
      library_path: libraryPath,
      timezone: "Asia/Bangkok",
      locale: locale as LanguageCode,
      symbol_search_request_delay: 400,
      disabled_features,
      enabled_features: ["study_templates"],
      charts_storage_url: chartsStorageUrl,
      charts_storage_api_version: chartsStorageApiVersion,
      client_id: clientId,
      user_id,
      fullscreen: fullscreen,
      // autosize: autosize,
      studies_overrides: studiesOverrides,
      load_last_chart: isLoadLastChart,
      theme: theme as ThemeName,
      overrides: {
        ...overrides,
        "scalesProperties.showStudyLastValue": false,
        "scalesProperties.fontSize": isMobile ? 10 : undefined,
        "paneProperties.crossHairProperties.color": "#758696",
        "scalesProperties.showSymbolLabels": false,
      },
    });

    setCloneTvWidget(tvWidget);

    tvWidget.onChartReady(() => {
      hideTools(tvWidget);
      const oldRef = loadRefWithSymbol(tvWidget);
      if (oldRef && isAutosave) {
        addConfigParams(tvWidget, oldRef, theme == "Dark");
      } else {
        tvWidget.chart().removeAllStudies();
        addConfigParams(tvWidget, undefined, theme == "Dark");
      }
      addTheme(tvWidget, theme as string);
      tvWidget
        .chart()
        .onSymbolChanged()
        .subscribe(null, () => {
          const symbolName = tvWidget.symbolInterval().symbol;
          const localSymbol = localStorage.getItem(STORAGE_SYMBOL_KEY);
          if (domain && window !== window.top && symbolName !== localSymbol) {
            setIsLoading(true);
            setTimeout(() => {
              localStorage.setItem(STORAGE_SYMBOL_KEY, symbolName);
              window.parent.postMessage(
                {
                  symbol: symbolName,
                  source: "trading-view",
                  KEY_CHANGE_SYMBOL,
                },
                domain
              );
              setIsLoading(false);
            }, 1000);
          }
        });
      if (isAutosave) {
        tvWidget.subscribe("undo_redo_state_changed", (e) => {
          setTimeout(() => {
            tvWidget?.save((layout) => {
              saveRefWithSymbol(tvWidget);

              const activeIndicators: TIndicatior[] = [];
              const studies = tvWidget
                .chart()
                .getAllStudies()
                .map((item) => {
                  // chuyển sang object
                  /** Vui lòng xem kỹ type của inputValues,
                   * getInputValues trả ra mảng và hàm reduce convert thành object để tiện so sánh
                   */
                  const inputValues: { [key in TKeyStudy]?: StudyInputValue } =
                    tvWidget
                      .chart()
                      .getStudyById(item.id)
                      .getInputValues()
                      .reduce(
                        (
                          acc: { [key in TKeyStudy]?: StudyInputValue },
                          item
                        ) => {
                          acc[item.id as TKeyStudy] = item.value;
                          return acc;
                        },
                        {} as { [key in TKeyStudy]?: StudyInputValue }
                      );
                  return { ...item, inputValues };
                });

              const isExistsMA = movingAverageStudies.every((maStudy) =>
                studies.some(
                  (otherStudy) =>
                    (otherStudy.name as TShortId) === "Moving Average" &&
                    maStudy.length === otherStudy.inputValues.length
                )
              );
              if (isExistsMA) activeIndicators.push("ma");

              const isExistsEMA = emaStudies.every((study) =>
                studies.some(
                  (otherStudy) =>
                    (otherStudy.name as TShortId) ===
                      "Moving Average Exponential" &&
                    study.length === otherStudy.inputValues.length
                )
              );
              if (isExistsEMA) activeIndicators.push("ema");

              const isExistsMACD = macdStudies.every((study) =>
                studies.some(
                  (otherStudy) =>
                    (otherStudy.name as TShortId) === "MACD" &&
                    study.fastLength === otherStudy.inputValues?.in_0 &&
                    study.slowLength === otherStudy.inputValues.in_1 &&
                    study.source === otherStudy.inputValues.in_3
                )
              );
              if (isExistsMACD) activeIndicators.push("macd");

              const isExistsRsi = rsiStudies.every((study) =>
                studies.some(
                  (otherStudy) =>
                    (otherStudy.name as TShortId) ===
                      "Relative Strength Index" &&
                    study.length === otherStudy.inputValues?.length
                )
              );
              if (isExistsRsi) activeIndicators.push("rsi");

              const isExistsBoll = bollStudies.every((study) =>
                studies.some(
                  (otherStudy) =>
                    (otherStudy.name as TShortId) === "Bollinger Bands" &&
                    study.length === otherStudy.inputValues?.in_0 &&
                    study.mult === otherStudy.inputValues.in_1
                )
              );
              if (isExistsBoll) activeIndicators.push("boll");

              if (domain)
                window.parent.postMessage(
                  { KEY_SYNC_ACTIVE_INDICATORS, symbol, activeIndicators },
                  domain
                );
            });
          }, 1000);
        });
      }
    });

    window.addEventListener("storage", function (e) {
      const isDisableSyncSymbol = query.get("disablesyncsymbol") === "true";
      if (!isDisableSyncSymbol) {
        if (e.key === STORAGE_SYMBOL_KEY && e.newValue !== e.oldValue) {
          tvWidget.chart().setSymbol(e.newValue as string);
        }
      }
    });

    const handleBeforeUnload = (event?: BeforeUnloadEvent) => {
      // saveRefWithSymbol();
      localStorage.removeItem("tradingview.current_theme.name");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleBeforeUnload();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (tvWidget !== null) {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
        tvWidget.remove();
        localStorage.removeItem("tradingview.current_theme.name");
      }
    };
  }, []);

  return (
    <div>
      <div
        className={clsx(
          !isLoading && "hidden",
          "fixed flex justify-center items-center w-full h-full bg-black/70"
        )}
      >
        <div className="border-blue-600 h-16 w-16 animate-spin rounded-full border-4 border-t-gray-600" />
      </div>
      {isShowNote && (
        <div className="notes">
          <div className="content">
            <img className="click" src="/images/click.svg" alt="click" />
            <div className="text">
              <div>Giữ để theo dõi các giá trị</div>
              <button
                className="text-black"
                onClick={handleHideNotePressMobile}
              >
                Đã hiểu!
              </button>
            </div>
          </div>
        </div>
      )}
      <div id={containerId} className={"TVChartContainer"} ref={tvWidgetRef} />
    </div>
  );
};

export default TVChartContainer;
