import { useEffect } from "react";
import "./App.css";
import { DCHART_VERSION } from "./config";
import TVChartContainer from "./components/TVChartContainer";

export default function App() {
  useEffect(() => {
    window.dchartversion = DCHART_VERSION;
  }, []);

  return <TVChartContainer />;
}
